import React from 'react';

function SelectChevron(props) {
  return (
    <svg
      fill="rgba(0,0,0,.54)"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 10l5 5 5-5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

export default SelectChevron;
